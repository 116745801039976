@use '@lichtblick/photon/theme/global' as *;

.headline {
  margin-bottom: $spacing-m;
}

.list {
  margin-top: $spacing-xxs;

  & > :not(:last-of-type) {
    margin-bottom: $spacing-xs;
  }
}

.list-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  list-style: none;
}

.icon {
  flex-shrink: 0;
  margin-top: 0.375rem;
  margin-right: $spacing-xs;
}
