@use '@lichtblick/photon/theme/global' as *;

.button {
  display: flex;
  position: fixed;
  z-index: 999;
  right: $spacing-s;
  bottom: $spacing-m;
  align-items: center;
  justify-content: center;
  /* stylelint-disable-next-line meowtec/no-px */
  width: 48px;
  /* stylelint-disable-next-line meowtec/no-px */
  height: 48px;
  transform: translate3d(0, $spacing-xs, 0);
  transition-property: transform, opacity;
  transition-duration: 0.15s;
  transition-delay: 0.2s;
  border: $border-width-input solid $color-black;
  /* stylelint-disable-next-line meowtec/no-px */
  border-radius: 48px;
  opacity: 0;
  background-color: $color-white;
  pointer-events: none;

  @media (min-width: $mq-medium-and-above) {
    right: $spacing-xl;
  }

  @media (min-width: $mq-large-and-above) {
    right: max($spacing-xxs, 50vw - (#{$breakpoint-large}px / 2) + $spacing-xl);
    bottom: $spacing-xl;
  }

  &:hover {
    background-color: $color-light-gray;
  }

  &:active {
    outline: none;
  }

  &.visible {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    pointer-events: initial;
  }
}
