@use '@lichtblick/photon/theme/global' as *;

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;

  @media (min-width: $mq-medium-and-above) {
    position: absolute;
  }

  .has-embed & {
    position: static;

    :global(.picture-mobile) img {
      aspect-ratio: 5 / 2;
    }

    @media (min-width: $mq-medium-and-above) {
      position: absolute;
    }
  }
}

.stage {
  --overlay-angle: 0;

  display: flex;
  position: relative;
  align-items: flex-end;
  min-height: 32rem;
  padding: $spacing-s 0;
  overflow: hidden;

  &-bg-black {
    @include background-text-color($color-black);

    --overlay-color: #{$color-black};
  }

  &-bg-white {
    @include background-text-color($color-white);

    --overlay-color: #{$color-white};
  }

  @media (min-width: $mq-medium-and-above) {
    --overlay-angle: 90deg;

    align-items: center;
    height: calc(100vh - 11rem);
    max-height: 45rem;
    padding: $spacing-xl 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    background: linear-gradient(var(--overlay-angle), var(--overlay-color), transparent 80%);
    pointer-events: none;
  }

  &.has-embed {
    @media (max-width: $mq-below-medium) {
      flex-direction: column;
      min-height: 0;
      padding: 0;
      background-color: $color-faded-gray;
      color: $color-black;

      &::after {
        display: none;
      }
    }

    @media (min-width: $mq-medium-and-above) {
      margin-bottom: -6.5rem;
    }
  }
}

.text-intro {
  .has-embed & {
    padding: $spacing-xs 0 $spacing-xxs;
  }
}

.app-badges {
  margin-top: $spacing-xxs;
}

.grid-container {
  position: relative;
  z-index: 1;
  width: 100%;
}

.badge {
  display: flex;
  position: absolute;
  top: $spacing-xs;
  left: $spacing-xs;
  align-items: center;
  justify-content: center;
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;

  &-bg-orange {
    @include background-text-color($color-orange);
  }

  &-bg-green {
    @include background-text-color($color-green);
  }

  &-bg-light-green {
    @include background-text-color($color-light-green);
  }

  &-bg-dark-aqua {
    @include background-text-color($color-dark-aqua);
  }

  &-bg-dark-berry {
    @include background-text-color($color-dark-berry);
  }

  &-text {
    font-size: 0.875rem;
    line-height: 110%;
    text-align: center;
  }

  &-headline {
    font-size: 2.5rem;
    line-height: 110%;
    text-align: center;
  }

  @media (min-width: $mq-medium-and-above) {
    top: 4rem;
    right: 4rem;
    left: unset;
    width: 8.25rem;
    height: 8.25rem;
  }
}
