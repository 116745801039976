@use 'sass:math';
@use '@lichtblick/photon/theme/global' as *;

.section {
  padding-top: $spacing-l;
  padding-bottom: $spacing-l;
  scroll-margin-top: $spacing-3xl;

  @include content-container;

  @media (min-width: $mq-small-and-above) {
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;
  }

  @each $color-name, $color-value in $cms-colors {
    &-bg-#{'' + $color-name} {
      @include background-text-color($color-value);
    }
  }
}

.text-intro {
  margin-bottom: $spacing-m;
}

.columns-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: $spacing-m;

  @media (min-width: $mq-medium-and-above) {
    grid-template-columns: 1fr 1fr;
    gap: $spacing-xl;
  }
}

.image {
  align-self: center;
}
