@use '../../theme/colors' as *;

.stepper {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .input-row {
    position: relative;
    pointer-events: none;

    & > input {
      position: relative;
      pointer-events: all;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: none;
  color: $color-black;
  cursor: pointer;
  pointer-events: all;

  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }
}
