@use 'sass:math';
@use '@lichtblick/photon/theme/global' as *;

.section {
  padding-top: $spacing-m;
  padding-bottom: $spacing-m;

  @media (min-width: $mq-medium-and-above) {
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;
  }

  &-bg-white {
    @include background-text-color($color-white);
  }

  &-bg-faded-gray {
    @include background-text-color($color-faded-gray);
  }

  @include content-container;
  @include padding-classes;
}

.content {
  padding: $spacing-s $spacing-xs;
  border-radius: $border-radius-s;

  @media (min-width: $mq-medium-and-above) {
    width: math.div(10, 12) * 100%;
    margin: 0 auto;
    padding: $spacing-m math.div(1, 12) * 100%;
  }

  .section-bg-white & {
    @include background-text-color($color-faded-gray);
  }

  .section-bg-faded-gray & {
    @include background-text-color($color-white);
  }
}

.headline {
  margin-bottom: $spacing-xs;
}

.expander-button {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
