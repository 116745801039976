@use '@lichtblick/photon/theme/global' as *;

.section {
  position: sticky;
  z-index: 10;
  bottom: 0;
}

.wrapper {
  width: 100%;
  padding: $spacing-s;
  outline: 1px solid $color-light-gray;
  background-color: $color-white;

  @media (min-width: $mq-medium-and-above) {
    position: absolute;
    right: 0;
    bottom: $spacing-s;
    width: 17.5rem;
    margin-right: $spacing-s;
    border-radius: $border-radius-s;
  }
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: $spacing-s;
  background: none;
  color: $color-black;
}

.image {
  display: none;

  @media (min-width: $mq-medium-and-above) {
    display: block;
    margin-bottom: $spacing-xs;
  }
}

.headline {
  margin: 0 $spacing-s $spacing-xs 0;

  &:last-child {
    margin-bottom: 0;
  }
}
