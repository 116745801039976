@use 'sass:list';
@use 'sass:math';
@use '@lichtblick/photon/theme/global' as *;

.icon {
  display: grid;
  grid-area: icon;
  align-items: center;
  justify-content: center;
  width: 2.875rem;
  height: 2.875rem;
  border-radius: 50%;
  background-color: $color-white;

  @each $color-name, $color-value in $cms-colors {
    &-color-#{'' + $color-name} {
      color: $color-value;

      @if list.index($dark-colors, $color-value) {
        background: $color-white;
      } @else {
        background: $color-black;
      }
    }
  }
}

.container {
  display: grid;
  grid-template-areas: 'icon' '.' 'headline' '.' 'text';
  grid-template-rows: auto $spacing-m auto $spacing-xxs 1fr;

  &:has(.link) {
    grid-template-areas: 'icon' '.' 'headline' '.' 'text' '.' 'button';
    grid-template-rows: auto $spacing-m auto $spacing-xxs 1fr $spacing-m auto;
  }

  flex-basis: 100%;
  padding: $spacing-s;
  border-radius: $border-radius-s;

  @each $color-name, $color-value in $cms-colors {
    &-bg-#{'' + $color-name} {
      background-color: $color-value;

      @include get-text-color($color-value);
    }
  }

  &-text-align-center {
    text-align: center;

    .icon {
      margin: 0 auto;
    }
  }

  &-text-align-left {
    text-align: left;
  }

  &-width-wide {
    @media (min-width: $mq-medium-and-above) {
      flex-basis: calc(math.div(100%, 12) * 5 - math.div(2rem, 3));
    }
  }

  &-width-narrow {
    @media (min-width: $mq-medium-and-above) {
      flex-basis: calc(math.div(100%, 12) * 4 - math.div(2rem, 3));
    }
  }
}

.headline {
  grid-area: headline;
}

.text {
  grid-area: text;
}

.link {
  grid-area: button;
}
