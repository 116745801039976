@use '@lichtblick/photon/theme/global' as *;

.accordion-trigger {
  display: flex;
  align-items: center;
  width: 100%;
  padding: $spacing-xxs $spacing-xs;
  transition: background-color $large-intro;
  border-radius: $border-radius-xs $border-radius-xs 0 0;
  background: $color-white;
  gap: $spacing-xxs;

  @include focus-ring;

  @media (min-width: $mq-small-and-above) {
    padding: $spacing-xs $spacing-s;
    gap: $spacing-xs;
  }

  & > :last-child {
    flex-shrink: 0;
    margin-left: auto;
  }

  &[data-state='closed'] {
    border-radius: $border-radius-xs;

    .chip {
      background: $color-faded-gray;
    }
  }

  .radio-button {
    margin-top: 0;
  }

  &[data-state='open'] {
    transition: background-color $large-outro;

    @each $color-name, $color-value in $colors {
      &.accordion-trigger-bg-#{'' + $color-name} {
        background-color: $color-value;
      }
    }

    .radio-button {
      background: $color-white;

      &::after {
        opacity: 1;
      }
    }
  }
}
