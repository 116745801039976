@use '../../theme/spacing' as *;
@use '../../theme/colors' as *;
@use '../../theme/mixins/focus-ring' as *;
@use '../../theme/animations' as *;

@keyframes slide-down {
  from {
    height: 0;
    overflow: hidden;
  }

  to {
    height: var(--radix-accordion-content-height);
    overflow: auto;
  }
}

@keyframes slide-up {
  from {
    height: var(--radix-accordion-content-height);
    overflow: auto;
  }

  to {
    height: 0;
    overflow: hidden;
  }
}

.item {
  &:focus-within {
    position: relative;
    z-index: 1;

    /* Add box-shadow to focus-within if a11y is required */
  }
}

.trigger {
  width: 100%;
  border-radius: $spacing-xs;
  background-color: transparent;
  color: $color-black;
  font-family: inherit;
  text-align: left;

  &:focus-visible {
    /* stylelint-disable-next-line meowtec/no-px */
    outline-offset: -1px;

    @include focus-ring;
  }
}

.content {
  padding: 0 !important; /* Do not overwrite. Set padding on children elements. */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  &-animation {
    &[data-state='open'] {
      animation: slide-down $large-intro;
    }

    &[data-state='closed'] {
      animation: slide-up $large-outro;
    }
  }
}
