@use '@lichtblick/photon/theme/global' as *;

.headline {
  margin-bottom: $spacing-m;
}

.list {
  display: grid;
  grid-template-columns: max-content auto;
  margin-top: $spacing-xxs;

  & > :not(:last-of-type) {
    margin-bottom: $spacing-xs;
  }
}
