@use 'sass:math';
@use '@lichtblick/photon/theme/global' as *;

.section {
  scroll-margin-top: 8rem;

  @include padding-classes;

  @each $color-name, $color-value in $colors {
    &-bg-#{'' + $color-name} {
      @include background-text-color($color-value);
    }
  }
}

.text-intro {
  margin-bottom: $spacing-l;

  @include content-container;

  @media (min-width: $mq-medium-and-above) {
    & > * {
      width: math.div(100%, 12) * 8;
    }
  }
}
