@use '../../theme/animations' as *;
@use '../../theme/border' as *;
@use '../../theme/colors' as *;
@use '../../theme/opacities' as *;
@use '../../theme/spacing' as *;
@use '../../theme/state-colors' as *;

.radio-button {
  display: flex;
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  margin-top: calc(calc(1.7rem - calc($spacing-xs)) / 2);
  border: $border-width-input solid;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  appearance: none;

  /* Focus indicator */
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    transition: opacity $small-intro;
    border: 0.375rem solid rgba($color-orange, $opacity-light);
    border-radius: 50%;
    opacity: 0;
  }

  /* Little "dot" */
  &::after {
    content: '';
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    transition: $small-intro;
    border-radius: 50%;
    opacity: 0;
  }

  &:focus-visible::before {
    opacity: 1;
  }

  &:checked {
    background: $color-orange;

    &::after {
      opacity: 1;
    }
  }
}

.state-default {
  border-color: $color-black;

  &::after {
    background-color: $color-black;
  }
}

.state-error {
  border-color: $state-color-error;

  &::after {
    background-color: $state-color-error;
  }
}

.state-disabled {
  border-color: $state-color-inactive-light;

  &::after {
    background-color: $state-color-inactive-light;
  }
}
