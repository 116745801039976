@use '@lichtblick/photon/theme/global' as *;

.select-input-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: $breakpoint-large;
  margin: $spacing-xs auto (-1 * $spacing-m) auto;
  padding: 0 $spacing-xs;

  @media (min-width: $mq-medium-and-above) {
    margin-top: $spacing-s;
    padding: 0 $spacing-xl;
  }
}

.select-input {
  width: 100%;

  @media (min-width: $mq-medium-and-above) {
    width: auto;
    /* stylelint-disable-next-line meowtec/no-px */
    min-width: 160px;
  }
}
