@use '@lichtblick/photon/theme/global' as *;

.section {
  @include content-container;
  @include padding-classes;
  @include background-text-color($color-dark-smoke);
}

svg.loader {
  display: block;
  width: 5rem;
  margin: $spacing-xs auto;

  @media (min-width: $mq-medium-and-above) {
    width: 8rem;
    margin: $spacing-m auto;
  }
}
