@use 'sass:math';
@use '@lichtblick/photon/theme/global' as *;

.nav {
  display: flex;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  /* stylelint-disable-next-line meowtec/no-px */
  height: 40px;
  transition: opacity 0.15s ease-in-out;
  opacity: 0;
  background-color: $color-faded-gray;
  /* stylelint-disable-next-line meowtec/no-px */
  box-shadow: 0 math.div($spacing-3xs, 2) $spacing-3xs rgb(0 0 0 / 10%);
  pointer-events: none;

  @include content-container;

  &.visible {
    opacity: 1;
    pointer-events: initial;
  }
}

.flyout {
  position: absolute;
  z-index: 999;
  /* stylelint-disable-next-line meowtec/no-px */
  top: 40px;
  left: 0;
  width: 100%;
  max-height: 0;
  padding: 0 $spacing-xxs;
  overflow: hidden;
  overflow-y: hidden;
  list-style-type: none;
  transition: max-height 0.15s ease-in-out;
  border-bottom-right-radius: $border-radius-m;
  border-bottom-left-radius: $border-radius-m;
  background-color: $color-white;
  box-shadow: 0 $spacing-3xs $spacing-xxs rgb(0 0 0 / 10%);

  @media (min-width: $mq-medium-and-above) {
    padding-right: $spacing-s;
    padding-left: $spacing-s;
  }

  li {
    &:not(:last-child) {
      border-bottom: 1px solid $color-faded-gray;
    }

    &:last-child {
      margin-bottom: $spacing-xxs;

      @media (min-width: $mq-medium-and-above) {
        margin-bottom: $spacing-xs;
      }
    }
  }

  a {
    display: flex;
    gap: $spacing-xxs;
    margin: $spacing-3xs 0;
    padding: $spacing-xxs;
    border-radius: $border-radius-s;
    font-size: 1rem;
    font-weight: $font-weight-bold;
    line-height: 170%;
    cursor: pointer;

    @include focus-ring;

    @media (min-width: $mq-small-and-above) {
      padding: $spacing-xxs $spacing-xxs;
    }

    &:hover {
      background: $color-faded-gray;
    }

    .flyout-link-icon {
      flex-shrink: 0;
      margin-top: 0.275rem;
    }

    .flyout-link-text {
      display: block;
      width: 100%;
    }
  }

  &.expanded {
    max-height: 85vh;
    max-height: 85dvh;
    overflow-y: auto;
  }

  @media (min-width: $mq-small-and-above) {
    left: $spacing-xxs;
    /* stylelint-disable-next-line meowtec/no-px */
    width: 460px;
    padding: 0 $spacing-xs;
  }

  @media (min-width: $mq-medium-and-above) {
    left: max(2.5rem, calc((100% - #{$breakpoint-large}px) / 2 + 2.5rem));
  }
}

.overlay {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  background-color: rgba($color-black, 0.8);
  pointer-events: none;

  &.expanded {
    opacity: 1;
    pointer-events: initial;
  }
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  color: $color-black;
  cursor: pointer;

  & > :first-child {
    margin-right: $spacing-xxs;
  }
}
