@use 'sass:math';
@use '@lichtblick/photon/theme/global' as *;

.container {
  overflow-x: auto;
  border: 2px solid $color-light-gray;
  border-radius: $border-radius-s;
  background-color: $color-white;
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;

  th {
    background-color: $color-faded-gray;
    text-align: left;
  }

  th,
  td {
    padding: $spacing-xs $spacing-xxs;
    border-bottom: 2px solid $color-light-gray;
    vertical-align: baseline;

    @media (min-width: $mq-small-and-above) {
      padding: $spacing-s $spacing-xs;
    }

    &:first-of-type {
      padding-left: $spacing-xs;

      @media (min-width: $mq-small-and-above) {
        padding-left: $spacing-s;
      }
    }

    &:last-of-type {
      padding-right: $spacing-xs;

      @media (min-width: $mq-small-and-above) {
        padding-right: $spacing-s;
      }
    }
  }

  tr {
    height: 3rem;

    &:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}
