@use '@lichtblick/photon/theme/global' as *;

$half-counter-small: 2.2rem;
$half-counter: 5rem;

.wrapper {
  position: relative;

  html:has(&) {
    scroll-behavior: smooth;
  }
}

.bar {
  position: absolute;
  top: 7rem;
  right: calc($spacing-s + $half-counter-small - 0.25rem);
  bottom: 7rem;
  width: 0.5rem;
  border-radius: 0.25rem;

  @media (min-width: $mq-medium-and-above) {
    top: 50vh;
    top: 50dvh;
    bottom: 50vh;
    bottom: 50dvh;
    left: calc(50% - 0.65rem);
    width: 1.3rem;
    border-radius: 0.65rem;
  }
}

.counter-wrapper {
  position: absolute;
  top: 7rem;
  right: $spacing-s;
  bottom: 7rem;
  pointer-events: none;

  @media (min-width: $mq-medium-and-above) {
    top: calc(50vh - $half-counter);
    top: calc(50dvh - $half-counter);
    right: 0;
    bottom: calc(50vh - $half-counter);
    bottom: calc(50dvh - $half-counter);
    left: 0;
  }
}

.counter {
  display: flex;
  position: sticky;
  top: calc(50dvh - $half-counter-small);
  right: 2rem;
  bottom: calc(50vh - $half-counter);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2 * $half-counter-small;
  height: 2 * $half-counter-small;
  border-radius: $half-counter-small;
  background: $color-dark-smoke;
  box-shadow:
    0 0 0 0.6rem rgb(255 255 255 / 50%),
    0 1rem 1rem rgb(0 0 0 / 20%);
  color: $color-white;

  @media (min-width: $mq-medium-and-above) {
    top: calc(50vh - $half-counter);
    top: calc(50dvh - $half-counter);
    right: unset;
    left: calc(50% - $half-counter);
    width: calc(2 * $half-counter);
    height: calc(2 * $half-counter);
    border-radius: $half-counter;
    box-shadow:
      0 0 0 1rem rgb(255 255 255 / 50%),
      0 1rem 1rem rgb(0 0 0 / 20%);
  }

  .value {
    font-size: 1.5rem;
    line-height: 1.5rem;

    @media (min-width: $mq-medium-and-above) {
      font-size: 3.5rem;
      line-height: 3.5rem;
    }
  }

  .unit {
    font-size: 0.6rem;

    @media (min-width: $mq-medium-and-above) {
      font-size: 1rem;
    }
  }
}
