@mixin shared-icon-wrapper {
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 100%;
  background: none;
  cursor: pointer;
  appearance: none;
}
