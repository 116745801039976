@use '../../theme/colors' as *;
@use '../../theme/opacities' as *;
@use '../../theme/spacing' as *;

label.label {
  display: flex;
  align-items: flex-start;
  gap: $spacing-xxs;
  color: $color-black;
  cursor: pointer;

  &-disabled {
    color: rgba($color-black, $opacity-disabled);
  }

  & > span {
    text-align: left;
  }
}
