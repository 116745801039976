@use '../../theme/spacing' as *;
@use '../../theme/font-weight' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;
  border: none;
}

.legend {
  margin-bottom: 0.75rem;
  font-weight: $font-weight-bold;
}
